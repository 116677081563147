import * as React from "react";
import { useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { createEnvironment } from "./environment";
import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";

export default function RelayEnvironment({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { rc } = useGlobalConfig();

  const environment = useMemo(() => {
    return createEnvironment();
  }, []);
  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  );
}
