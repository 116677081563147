import { RunContextApp } from "RunContext/RCApp";
import {
  Store,
  RecordSource,
  Environment,
  Network,
  Observable,
} from "relay-runtime";
import { FetchFunction, IEnvironment } from "relay-runtime";
import { GQL } from "utils/constants";
import request from "./networkServiceInstances";

const fetchFn: FetchFunction = (params, variables) => {
  const response = request.post(`${GQL}/graphql`, {
    query: params.text,
    variables,
  });
  return Observable.from(
    response.then((data) => {
      return data?.data;
    })
  );
};

export function triggerRelayQuery(
  query: any,
  variables: any,
  rc: RunContextApp
) {
  const apiClient = rc.apiClient;
  return apiClient.post(`${GQL}/graphql`, {
    query,
    variables,
  });
}

export function createEnvironment(): IEnvironment {
  const network = Network.create(fetchFn);
  const store = new Store(new RecordSource());
  return new Environment({ store, network });
}
