import React from "react";
import { Formik, FormikHelpers } from "formik";
import { CDN_URL } from "utils/constants";
import { graphql } from "relay-runtime";
import useLogin from "hooks/useLogin";
import { BackgroundGradientAnimation } from "@/components/ui/background-ui-animation";
import Header from "Shared/Header";
import PublicHeader from "./PublicHeader";
import { LampContainer } from "@/components/ui/lamp";
import { motion } from "framer-motion";

export interface formValues {
  username: string;
  password: string;
}

const PublicPage = () => {
  return (
    <>
      <BackgroundGradientAnimation
        gradientBackgroundStart="rgb(40 57 88)"
        gradientBackgroundEnd="rgb(8 11 18)"
      >
        <PublicHeader />

        <LampContainer>
          <motion.h1
            initial={{ opacity: 0.5, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
          >
            Guiding wellness, fostering vitality.
          </motion.h1>
        </LampContainer>
      </BackgroundGradientAnimation>
    </>
  );
};

export default PublicPage;
