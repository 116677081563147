export const CDN_URL = "hale-assets-eha4dnapbxakc4db.z01.azurefd.net";
export const CDN_REPORTS_QA_DEV =
  "halereportsqadev-dzgybpfveqawcyd9.z01.azurefd.net";

export const JOIN_CALL_ENDPOINT = "https://api.recall.ai/api/v1/bot";

export const GRAPHQL_ENDPOINT = "http://127.0.0.1:8000";

export const GQL = process.env.REACT_APP_BACKEND_QUERY_SERVICES;
export const MetricName: any = {
  COMPLIANCE_COMPLETE: "Compliance complete %",
  CLINICIAN_SPEAK_TIME: "Clinician speaking time %",
  CULTIVATING_CHANGE_TALK: "Cultivating change talk",
  SOFTENING_COUNTER_CHANGE_TALK: "Softening counter change talk",
  EMPATH_SCORE: "Empathy score",
  PARTNERSHIP_SCORE: "Partnership score",
  SMART_GOAL_SCORE: "SMART goal score",
  CLIENT_CHANGE_TAL: "Client change talk %",
  SHARE_COMPLEX_REFLECTIONS: "Share of complex reflections %",
  SHARE_OPEN_QUESTIONS: "Share of open questions %",
  ASSERTIONS_PER_SESSION: "# affirmations / session",
};
