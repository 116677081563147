import React, { useEffect, useState, Suspense, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { graphql, useLazyLoadQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { performanceHeaders } from "../../utils/labels";
import { Typography } from "@mui/material";

const AllMeetingMetricsQuery = graphql`
  query ManagerDashboardQuery {
    allMeetingMetricsNew {
      meetingId
      userId
      orgId
      metrics
      email
    }
  }
`;
function ManagerDashboardInner({ queryReference }: { queryReference: PreloadedQuery<any> }) {
  const [rowData, setRowData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [uniqueKeys, setUniqueKeys] = useState<Set<string>>(new Set());

  const data = usePreloadedQuery(AllMeetingMetricsQuery, queryReference);

  useEffect(() => {
    console.log("Data received:", data);
    if (data && (data as any).allMeetingMetricsNew) {
      try {
        const formattedData = (data as any).allMeetingMetricsNew.map((item: any) => {
          const metricsStr = item.metrics
            .replace(/'/g, '"')
            .replace(/"\s*([^"]+)\s*":/g, '"$1":');
          const metrics = JSON.parse(metricsStr);
          console.log("Metrics:", metrics);

          // Update uniqueKeys set
          setUniqueKeys(prevKeys => {
            const newKeys = new Set(prevKeys);
            Object.keys(metrics).forEach(key => newKeys.add(key));
            return newKeys;
          });

          return {
            userId: item.userId,
            email: item.email,
            ...Object.fromEntries(
              Object.entries(metrics).map(([key, value]: [string, any]) => [
                key,
                { 
                  score: parseFloat(value.score), 
                  min_score: parseFloat(value.min_score), 
                  max_score: parseFloat(value.max_score) 
                }
              ])
            )
          };
        });
        setRowData(formattedData);
      } catch (err) {
        console.error("Error formatting data:", err);
        setError("Error formatting data. Check console for details.");
      }
    } else {
      console.log("No data received or data in unexpected format");
      setError("No data received or data in unexpected format");
    }
  }, [data]);

  const columnDefs = useMemo(() => {
    if (rowData.length === 0) return [];
    
    return [
      { 
        headerName: "Email", 
        field: "email",
        cellRenderer: (params: any) => {
          return <strong>{params.value}</strong>;
        }
      },
      ...Array.from(uniqueKeys)
        .filter(key => key in performanceHeaders)
        .sort((a, b) => {
          const posA = performanceHeaders[a as keyof typeof performanceHeaders]?.columnPosition ?? Infinity;
          const posB = performanceHeaders[b as keyof typeof performanceHeaders]?.columnPosition ?? Infinity;
          return posA - posB;
        })
        .map((key) => ({
          headerName: performanceHeaders[key as keyof typeof performanceHeaders].title,
          field: key,
          valueFormatter: (params: { data?: Record<string, any> }) => {
            const value = params.data ? params.data[key] : null;
            if (typeof value === 'object' && value !== null && 'score' in value) {
              return `${value.score}`;
            }
            return value;
          },
          tooltipValueGetter: (params: any) => {
            const value = params.data[key];
            if (typeof value === 'object' && value !== null && 'score' in value) {
              return `Min: ${value.min_score}, Max: ${value.max_score}`;
            }
            return null;
          }
        }))
    ];
  }, [rowData, uniqueKeys]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (rowData.length === 0) {
    return <div>Loading... (If this persists, there might be no data available)</div>;
  }

  return (
    <div>
      <Typography variant="h5">Team Overview</Typography>
      <div className="ag-theme-quartz mt-4" style={{ height: 500 }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        domLayout='autoHeight'
        animateRows={true}
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
      />
    </div>  
    </div>
  );
}

const ManagerDashboard = () => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(AllMeetingMetricsQuery);

  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  if (!queryReference) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ManagerDashboardInner queryReference={queryReference} />
    </Suspense>
  );
};

export default ManagerDashboard;
