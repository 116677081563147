import React, { forwardRef } from "react";
import CloseBtn from "./CloseButton";

type Props = {
  children: React.ReactNode;
  toggleDialog: () => void;
};

const Dialog = forwardRef<HTMLDialogElement, Props>(
  ({ children, toggleDialog }, ref) => {
    return (
      <dialog
        ref={ref}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            toggleDialog();
          }
        }}
        className="relative rounded-lg"
      >
        <div>
          <CloseBtn onClick={toggleDialog} />
          {children}
        </div>
      </dialog>
    );
  }
);

export default Dialog;
