import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  InitConfig,
  RunContextApp,
  RunState,
  RUN_MODE,
} from "RunContext/RCApp";
import App from "./src/App";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const runMode = RUN_MODE.DEV;
//   process.env.NODE_ENV === "production" ? RUN_MODE.PROD : RUN_MODE.DEV;
// const logLevel =
//   process.env.NODE_ENV === "production" ? LOG_LEVEL.NONE : LOG_LEVEL.DEBUG;

const ic = new InitConfig(runMode, true);
const rs = new RunState();
const rc = new RunContextApp(ic, rs);

rc.init();

const rootDiv = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootDiv);

root.render(<App rc={rc} />);
