import React from "react";
import useSso from "./useSaphire";
import useLogin from "hooks/useLogin";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineErrorOutline } from "react-icons/md";

const Saphire = () => {
  const { handleUserLogin, successful } = useLogin();
  const { isLoading, error } = useSso(handleUserLogin);
  return (
    <div className="flex items-center justify-center w-full h-[100vh] flex-col">
      <>
        {" "}
        <AiOutlineLoading3Quarters className="animate-spin text-4xl" />
        <span className="mt-4">Logging in...</span>
      </>

      {!isLoading && !successful && (
        <>
          <MdOutlineErrorOutline className="text-4xl" />
          <span className="mt-4">
            We are unable to process your request. Please try after sometime.
          </span>
        </>
      )}
    </div>
  );
};

export default Saphire;
