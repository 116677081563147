import { RunContextApp } from "RunContext/RCApp";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

class ApiClient {
  private static instance: ApiClient;
  private axiosInstance: AxiosInstance;
  private runContextApp: RunContextApp;
  private constructor(private rc: RunContextApp) {
    this.runContextApp = rc;
    this.axiosInstance = axios.create();

    // Add request interceptor
    this.axiosInstance.interceptors.request.use(
      (config: any) => {
        // Modify the request config here
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "access_token"
        )}`;
        return config;
      },
      (error: any) => {
        // Handle request errors here
        console.log(error);
        return Promise.reject(error);
      }
    );

    // Add response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        // Modify the response data here
        return response.data;
      },
      (error: any) => {
        // Handle response errors here
        // If the request resulted in an error (e.g., network error, HTTP error)
        if (error.response) {
          // Access the HTTP error status code
          const errorCode = error.response.status;

          // Handle the error based on the status code
          switch (errorCode) {
            case 400:
              // Handle 400 Bad Request error
              break;
            case 401:
              this.runContextApp.session.logoutUser();
              break;
            case 404:
              // Handle 404 Not Found error
              break;
            // Add more cases for other status codes as needed
            default:
              // Handle other errors
              return Promise.reject(error);
          }
        }
      }
    );
  }

  public static getInstance(rc: RunContextApp): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(rc);
    }
    return ApiClient.instance;
  }

  public get(url: string, config?: AxiosRequestConfig): Promise<any> {
    return this.axiosInstance.get(url, config);
  }

  public post(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<any> {
    return this.axiosInstance.post(url, data, config);
  }

  public put(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<any> {
    return this.axiosInstance.put(url, data, config);
  }

  public delete(url: string, config?: AxiosRequestConfig): Promise<any> {
    return this.axiosInstance.delete(url, config);
  }
}

export default ApiClient;
