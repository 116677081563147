/**
 * @generated SignedSource<<f384bdb6dd8ebc7c89e9507a90a0d43a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ManagerDashboardQuery$variables = {};
export type ManagerDashboardQuery$data = {
  readonly allMeetingMetricsNew: ReadonlyArray<{
    readonly email: string;
    readonly meetingId: string;
    readonly metrics: string;
    readonly orgId: string;
    readonly userId: string;
  }>;
};
export type ManagerDashboardQuery = {
  response: ManagerDashboardQuery$data;
  variables: ManagerDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeetingMetrics",
    "kind": "LinkedField",
    "name": "allMeetingMetricsNew",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meetingId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orgId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metrics",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagerDashboardQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ManagerDashboardQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4cfe17db623c2e4a4f4400615e3a4269",
    "id": null,
    "metadata": {},
    "name": "ManagerDashboardQuery",
    "operationKind": "query",
    "text": "query ManagerDashboardQuery {\n  allMeetingMetricsNew {\n    meetingId\n    userId\n    orgId\n    metrics\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf9a015f1668eecd86055bfe10bd9285";

export default node;
