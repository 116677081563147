import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  onClick: (e?: any) => void;
  className?: string;
}

const CloseBtn = (props: Props) => {
  return (
    <button
      type="button"
      onClick={(e) => props.onClick(e)}
      className={twMerge(
        " top-0 right-0 bg-red-200 rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary",
        props.className
      )}
    >
      <span className="sr-only">Close menu</span>

      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
};

export default CloseBtn;
