import { RunContextApp } from "../RCApp";

export class AppConfigManager {
  private clientId = "";

  constructor(private rc: RunContextApp) {
    const url = window.location.href;
    if (url.match("^[a-zA-Zs]*$[.]audirie")) console.log(url);
  }
}
