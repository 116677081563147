import axios from "axios";

declare module "axios" {
  export interface AxiosRequestConfig {
    is_sl_token?: boolean;
  }
}

const commonApiConfig = {
  baseURL: process.env.REACT_APP_BACKEND_QUERY_SERVICES,
};

/**
 * Create an Axios Client
 */
const request = axios.create(commonApiConfig);

/**
 * Interceptor for intercepting request and attaching
 * Bearer/Auth Token here before dispatching api calls
 */
request.interceptors.request.use(
  async (apiConfig) => {
    const accessToken = localStorage.getItem("access_token");
    const config = apiConfig;

    const cancelController = new AbortController();
    if (!config.signal) config.signal = cancelController.signal;

    if (process.env.REACT_APP_API_BASE_URL_HOST !== "") {
      config.headers.host = process.env.REACT_APP_API_BASE_URL_HOST;
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default request;
