/*------------------------------------------------------------------------------
   About      : Global run context for the App
------------------------------------------------------------------------------*/

import ApiClient from "./ApiClient/apiClient";
import { AppConfigManager } from "./AppConfiguration/appConfigManger";
import { SessionManager } from "./session/sessionManager";

export enum RUN_MODE {
  DEV,
  QA,
  UAT,
  PROD,
}

export class InitConfig {
  constructor(
    public runMode: RUN_MODE,
    public consoleLogging: boolean
  ) {
    if (runMode === RUN_MODE.PROD || runMode === RUN_MODE.UAT) {
      console.log("You must turn off logging in production mode");
    }
  }
}

export class RunState {
  moduleLLMap: { [key: string]: any } = {};
  // modLogLevel    : LOG_LEVEL = LOG_LEVEL.NONE
  moduleNameMap: WeakMap<any, string> = new WeakMap();
}

export class RunContextApp {
  public session!: SessionManager;
  public appConfigManager!: AppConfigManager;
  public apiClient!: ApiClient;
  constructor(
    public initConfig: InitConfig,
    public runState: RunState
  ) {}

  init() {
    this.session = new SessionManager(this);
    this.appConfigManager = new AppConfigManager(this);
    this.apiClient = ApiClient.getInstance(this);
  }
}
