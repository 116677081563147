import { RunContextApp } from "../RCApp";

export const USER_ROLES = {
  CLINICIAN: "CLINICIAN",
  MANAGER: "MANAGER",
};
export type UserMetadata = {
  homePageHeader: string;
  tabs: string[];
};
export class SessionManager {
  private userRole = USER_ROLES.CLINICIAN;
  private loggedIn = false;
  private userId: string | null = null;
  private userName: string | null = null;
  private userMetadata: UserMetadata | null = null;
  private orgId: string | null = null;
  constructor(private rc: RunContextApp) {
    if (this.getFromStorage("userRole")) {
      this.setRole(this.getFromStorage("userRole")!);
    }
    if (this.getFromStorage("userId")) {
      this.setUserId(this.getFromStorage("userId")!);
    }
    if (this.getFromStorage("username")) {
      this.setUserName(this.getFromStorage("username")!);
    }
    if (this.getFromStorage("access_token")) {
      this.setLoggedIn(true);
    }
    if (this.getFromStorage("userMetadata")) {
      this.setUserMetadata(JSON.parse(this.getFromStorage("userMetadata")!));
    }
    if (this.getFromStorage("orgId")) {
      this.setOrgId(this.getFromStorage("orgId")!);
    }
  }

  setInStorage(k: any, v: any) {
    localStorage.setItem(k, v);
  }

  getFromStorage(k: any) {
    const value = localStorage.getItem(k);
    if (value === "undefined") {
      return null;
    }
    return value;
  }

  getLoggedIn() {
    return this.loggedIn;
  }

  setLoggedIn(isLoggedIn: boolean) {
    this.loggedIn = isLoggedIn;
  }

  getRole() {
    return this.userRole || this.getFromStorage("userRole");
  }

  setRole(role: string) {
    this.userRole = role;
    this.setInStorage("userRole", role);
  }

  getUserId() {
    return this.userId || this.getFromStorage("userId");
  }

  setUserId(userId: string) {
    this.userId = userId;
    this.setInStorage("userId", userId);
  }

  getUserName() {
    return this.userName || this.getFromStorage("username");
  }

  setUserName(userName: string) {
    this.userName = userName;
    this.setInStorage("username", userName);
  }

  getUserMetadata() {
    return this.userMetadata || JSON.parse(this.getFromStorage("userMetadata")!);
  }

  getOrgId() {
    return this.orgId || this.getFromStorage("orgId");
  }

  setOrgId(orgId: string) {
    this.orgId = orgId;
    this.setInStorage("orgId", orgId);
  }

  logoutUser() {
    this.setLoggedIn(false);
    this.setInStorage("access_token", null);
    window.location.replace("/");
  }

  setUserMetadata(userMetadata: UserMetadata) {
    this.userMetadata = userMetadata;
    this.setInStorage("userMetadata", JSON.stringify(userMetadata));
  }


}
