import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useSaphire = (handleUserLogin: Function) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { rc } = useGlobalConfig();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    try {
      handleUserLogin(
        searchParams.get("whois"),
        searchParams.get("lockin"),
        undefined,
        searchParams.get("orgId") || "audirie",
        searchParams.get("token")
      );
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  }, []);
  return {
    isLoading,
    error,
  };
};

export default useSaphire;
