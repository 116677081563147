import React from "react";
import { useRouteError } from "react-router-dom";

const Error = () => {
  const err = useRouteError();
  console.log(err);
  return (
    <div className="flex justify-center items-center m-80 flex-col">
      <p className="pb-4 text-2xl">Oops!</p>
      <p className="pb-4">Sorry, an unexpected error has occourred</p>
      <p className="text-slate-300 italic">
        If this problem persists, please try logging in again.
      </p>
    </div>
  );
};

export default Error;
