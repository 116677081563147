export const MODEL_NAME: any = {
  AGED_CARE: "Aged Care #1",
  AGED_CARE2: "Aged Care #2",
  HORMONAL_CONTRACEPTIVE: "Hormonal Contraceptive",
};

export const SIMULATION_METRICS: any = {
  counter_sustain_score: "Softening counter change talk score",
  cultivating_change_score: "Cultivating change talk score",
  empathy_score: "Empathy Score",
  partnership_score: "Partnership Score",
  stage1_time: "Stage 1 time",
  stage2_time: "Stage 2 time",
  stage3_time: "Stage 3 time",
  stage4_time: "Stage 4 time",
  stages_completed: "Stages Completed",
};

export const performanceHeaders = {
  stage1_time: { title: "Stage 1 time", min_score: 0.0, max_score: 1000.0, columnPosition: 1 },
  stage2_time: { title: "Stage 2 time", min_score: 0.0, max_score: 1000.0, columnPosition: 2 },
  stage3_time: { title: "Stage 3 time", min_score: 0.0, max_score: 1000.0, columnPosition: 3 },
  stage4_time: { title: "Stage 4 time", min_score: 0.0, max_score: 1000.0, columnPosition: 4 },
  qc_composite: { title: "Quality of counseling", min_score: 0.0, max_score: 100.0, columnPosition: 7 },
  empathy_score: { title: "Empathy", min_score: 0.0, max_score: 5.0, columnPosition: 6 },
  stages_completed: { title: "Stages completed", min_score: 0.0, max_score: 4.0, columnPosition: 5 },
  partnership_score: { title: "Partnership", min_score: 0.0, max_score: 5.0, columnPosition: 8 },
  counter_sustain_score: { title: "Countering sustain talk", min_score: 0.0, max_score: 5.0, columnPosition: 9 },
  cultivating_change_score: { title: "Cultivating change talk", min_score: 0.0, max_score: 5.0, columnPosition: 10 },
  // stage1_composite: { title: "Stage 1 composite", min_score: 0.0, max_score: 100.0, columnPosition: 11 },
  // stage2_composite: { title: "Stage 2 composite", min_score: 0.0, max_score: 100.0, columnPosition: 12 },
  // stage3_composite: { title: "Stage 3 composite", min_score: 0.0, max_score: 100.0, columnPosition: 13 },
  // stage4_composite: { title: "Stage 4 composite", min_score: 0.0, max_score: 100.0, columnPosition: 14 },
  diag_accuracy: { title: "Diagnostic accuracy", min_score: 0.0, max_score: 100.0, columnPosition: 15 },
  coverage_provided: { title: "Med reconciliation", min_score: 0.0, max_score: 100.0, columnPosition: 16 },
};
