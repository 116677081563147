import React from "react";
import { RunContextApp } from "RunContext/RCApp";
import { GlobalConfigProvider } from "RunContext/context-creation/GlobalConfigContext";
import RelayEnvironment from "./relay/RelayEnvironment";
import { Pages } from "./Pages";

interface AppProps {
  rc: RunContextApp;
}

const App = (props: AppProps) => {
  return (
    <GlobalConfigProvider rc={props.rc}>
      <RelayEnvironment>
        <Pages />
      </RelayEnvironment>
    </GlobalConfigProvider>
  );
};

export default App;
