/*------------------------------------------------------------------------------
   About      : Global App configs 
------------------------------------------------------------------------------*/

import { RunContextApp } from "../RCApp";
import React, { createContext, Dispatch } from "react";

interface GlobalConfigContextType {
  runMode: string;
  rc: RunContextApp;
}

const initialState: GlobalConfigContextType = {
  runMode: "WEB_DASHBOARD",
  rc: {} as RunContextApp,
};

export const GlobalConfigContext: React.Context<GlobalConfigContextType> =
  createContext(initialState);
const GlobalConfigDispatchContext = createContext<Dispatch<any> | null>(null);

// Outside of Provider
export const updateRunMode = (runMode: string) => {
  initialState.runMode = runMode;
};

export const GLOBAL_CONFIG_ACTIONS = {
  UPDATE_RUN_MODE: "UPDATE_RUN_MODE",
};

function globalConfigReducer(state: any, action: any) {
  switch (action.type) {
    case GLOBAL_CONFIG_ACTIONS.UPDATE_RUN_MODE:
      return { ...state, runMode: action.payload };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export interface GlobalConfigProviderType {
  rc: RunContextApp;
  children: React.ReactNode;
}

export function GlobalConfigProvider(args: GlobalConfigProviderType) {
  initialState.rc = args.rc;
  const [state, dispatch] = React.useReducer(globalConfigReducer, initialState);

  return (
    <GlobalConfigContext.Provider value={state}>
      <GlobalConfigDispatchContext.Provider value={dispatch}>
        {args.children}
      </GlobalConfigDispatchContext.Provider>
    </GlobalConfigContext.Provider>
  );
}

export function useGlobalConfig() {
  const context = React.useContext(GlobalConfigContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalConfig must be used within a GlobalConfigProvider"
    );
  }
  return context;
}

export function useGlobalConfigDispatch() {
  const context = React.useContext(GlobalConfigDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalConfigDispatch must be used within a GlobalConfigProvider"
    );
  }
  return context;
}
