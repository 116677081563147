export const JOIN_CALL_ENABLED = false;

export const user_based_headers_and_tabs = {
    SIMUSER: {
        homePageHeader: "Role plays",
        tabs: ["Role plays", "Insights","FAQs"]
    },
    CLINICIAN: {
        homePageHeader: "Trainings",
        tabs: ["Trainings", "Insights","FAQs"]
    }
}