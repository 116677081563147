import { useAuth } from "hooks/useAuth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedComponentProps {
  component: any;
  requestingRole: string[];
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component: Component,
  requestingRole,
  ...rest
}) => {
  const navigate = useNavigate();
  const { isAuthenticated, role } = useAuth();
  useEffect(() => {
    if (!isAuthenticated || !requestingRole.includes(role || "")) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <>
      {isAuthenticated && requestingRole.includes(role || "") ? (  
        <Component {...rest} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProtectedComponent;
